@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'), url(./fonts/HelveticaNeueMed.ttf);
}
.body {
  font-family: 'HelveticaNeue';
}
.App {
  text-align: center;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFE 36.45%);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/** header */
.header {
  display: block;
  /* background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFE 36.45%); */

}

header.cheryHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'HelveticaNeue';
  padding: 20px 0;
}

.cheryMenu ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
  padding: 0;
}

.cheryMenu ul li {
  padding: 0 15px;
  color: #0272BC;
  font-weight: 600;
  font-size: 12px;
}

.cheryMenu ul li a {
  color: #0272BC;
  text-decoration: none;
}

/** footer **/
.cheryfooter {
  background: linear-gradient(180deg, #FFFFFF 0%, #F7FAFE 36.45%);
  font-family: 'HelveticaNeue';
  padding-top: 10px;
}
.cheryfooter h3 {
  color: #515151;
}
.firstRow {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  color: #999999;
  margin-top: 50px;
}
.oneColumn ul {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: left;
}

.oneColumn ul li {
  padding: 0 5px;
}
.twoColumn ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  text-align: left;
}

.twoColumn ul li {
  padding: 5px 0;
  font-weight: 400;
  font-size: 14px;
}
.cheryfooter p {
  font-weight: 400;
  font-size: 14px;
}
.threeColumn {
  text-align: left;
}
.threeColumn p {
  margin: 7px 0;
}
.oneColumn {
  text-align: left;
}
.secondRow {
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-size: 12px;
  position: relative;
  padding: 30px 0;

}
.secondRow:before {
  content: '';
  border: 1px solid #E0F3FF;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  
}
/*** homecontent ***/
.sectionTwo, .sectionThree{background: #fff;}

.sectionTwo .two{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.sectionThree .three{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.sectionTwo .connnecttxt, .sectionTwo .connect {
  flex-basis:50%;
}

.sectionThree .popImg, .sectionThree .poptxt {
  flex-basis:50%;
}
.Hcontent h2 {
  font-family: 'HelveticaNeue';
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  color: #051F61;
  text-align: left;
}

.Hcontent p {
  font-family: 'HelveticaNeue';
  font-size: 13px;
  line-height: 21px;
  text-align: justify;
  color: #051F61;
}
.Hcontent a {
  font-family: 'HelveticaNeue';
  font-size: 13px;
  line-height: 15px;
  letter-spacing: -0.281699px;
  color: #E02236;
  float: right;
  font-weight: 600;
  border-bottom: 1px solid #E02236;
  padding-bottom: 2px;
}
.Hcontent h1 {
  font-family: 'HelveticaNeue';
  font-size: 31px;
  line-height: 37px;
  color: #051F61;
  font-weight: 600;
}
.sectionNews h3 {
  font-family: 'HelveticaNeue';
  font-size: 17px;
  line-height: 20px;
  letter-spacing: -0.319259px;
  color: #132C6A;
  font-weight: 600;
}
.sectionNews p {
  text-align: center;
}
.sectionOne p {
  text-align: center;
  margin: 0;
}
.headImg {
  background-image: url(/static/media/bg-pop.f487188a.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 470px;
}
.sectionNews input[type="email"] {
  padding: 0 5px;
  width: 255px;
  /* background: #F8FCFF;
  border: 1px solid #CAD9E3;
  box-sizing: border-box;
  border-radius: 5px; */
  height: 48px;
  border: none;
  background: #F8FCFF;
  font-size: 12px;
}
.sectionNews input[type="submit"] {
  background: #E02236;
  border-radius: 5px;
  border: none;
  color: #fff;
  height: 42px;
  width: 111px;
}
.customInput {
  width: 380px;
  margin: auto;
  background: #F8FCFF;
  border: 1px solid #CAD9E3;
  box-sizing: border-box;
  border-radius: 5px;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
input[type="email"]:focus {
  outline: none;
}
input[type="email"]:focus .customInput{
  outline: -webkit-focus-ring-color auto 1px;;
}
.sectionNews .News {
  width: 580px;
  background: #FFFFFF;
  border-radius: 14px;
  padding: 14px 0;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
/**login**/
.contentLogin p {
  font-family: 'HelveticaNeue';
  font-size: 12px;
  line-height: 21px;
  text-align: center;
  color: #051F61;
}
.contentLogin h1{
  font-family: 'HelveticaNeue';
  font-size: 31px;
  line-height: 37px;
  color: #051F61;
  font-weight: 600;
}
form#login {
  display: flex;
  flex-direction: column;
}
.formLogin {
  width: 440px;
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 9px rgba(227, 237, 248, 0.628651);
  border-radius: 14px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 50px 0 20px 0;
  z-index: 99;
  position: absolute;
  left: 0;
  right: 0;
}

#login input[type="number"], #login input[type="text"] {
  padding: 0 5px;
  width: 370px;
  height: 48px;
  margin: 8px auto;
  background: #F8FCFF;
  border: 1px solid #CAD9E3;
  box-sizing: border-box;
  border-radius: 5px;
}

#login input[type="submit"] {
  background: #E02236;
  border-radius: 5px;
  border: none;
  width: 160px;
  height: 42px;
  margin: 14px auto 0px auto;
  color: #fff;
}
/* .bgLogin {
  background-image: url(/static/media/bg-login.abb6ca38.png);
  background-repeat: no-repeat;
  background-size: 67%;
  background-position: center;
  padding: 89px 0;
} */
.formLogin p {padding: 0 30px;}
.formLogin input[type="number"]:focus, .formLogin input[type="text"]:focus {
  outline: none;
  border: 1px solid #132C6A !important;
}

.firstRow h3 {
  margin-bottom: 20px;
  FONT-WEIGHT: 600;
}

.secondRow .copyright span {
  padding-right: 40px;
}

.secondRow .copyright span:last-child {
  padding: 0;
}

.bgLogininside {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 520px;
  margin: auto;
  background-color: #ed213a;
  background-image: linear-gradient(180deg, #ed213a 0%, #93291e 100%);
  border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
  box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  animation: morphing 10s infinite;
  overflow: hidden;
  position: absolute;
  left: 0;
  right: 0;
}
.bgLogininside:hover {
  animation-play-state: paused;
}

@-moz-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
@-webkit-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
@-o-keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}
@keyframes morphing {
  0% {
    border-radius: 30% 70% 70% 30%/30% 30% 70% 70%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 58% 42% 75% 25%/76% 46% 54% 24%;
  }
  50% {
    border-radius: 50% 50% 33% 67%/55% 27% 73% 45%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 33% 67% 58% 42%/63% 68% 32% 37%;
  }
}


.bgLogin {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  width: 600px;
  height: 580px;
  margin: auto;
  background-color:#fbd8d8;
  background-image: linear-gradient(180deg, #fbd8d8 0%, #fbd8d8 100%);
  border-radius: 63% 37% 81% 19% / 42% 31% 69% 58% ;
  box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  animation: morphingbr 10s infinite;
  overflow: hidden;
}
.bgLogin:hover {
  animation-play-state: paused;
}

@-moz-keyframes morphingbr {
  0% {
    border-radius: 63% 37% 81% 19% / 42% 31% 69% 58%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 63% 37% 72% 28% / 79% 79% 21% 21% ;
  }
  50% {
    border-radius: 30% 70% 72% 28% / 31% 79% 21% 69%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 53% 47% 22% 78% / 75% 17% 83% 25% ;
  }
}
@-webkit-keyframes morphingbr {
  0% {
    border-radius: 63% 37% 81% 19% / 42% 31% 69% 58%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 63% 37% 72% 28% / 79% 79% 21% 21% ;
  }
  50% {
    border-radius: 30% 70% 72% 28% / 31% 79% 21% 69%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 53% 47% 22% 78% / 75% 17% 83% 25% ;
  }
}
@-o-keyframes morphingbr {
  0% {
    border-radius: 63% 37% 81% 19% / 42% 31% 69% 58%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 63% 37% 72% 28% / 79% 79% 21% 21% ;
  }
  50% {
    border-radius: 30% 70% 72% 28% / 31% 79% 21% 69%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 53% 47% 22% 78% / 75% 17% 83% 25% ;
  }
}
@keyframes morphingbr {
  0% {
    border-radius: 63% 37% 81% 19% / 42% 31% 69% 58%;
    box-shadow: 15px 15px 50px rgba(0,0,0,0.2);
  }
  25% {
    border-radius: 63% 37% 72% 28% / 79% 79% 21% 21% ;
  }
  50% {
    border-radius: 30% 70% 72% 28% / 31% 79% 21% 69%;
    box-shadow: -10px -5px 50px rgba(0,0,0,0.2);
  }
  75% {
    border-radius: 53% 47% 22% 78% / 75% 17% 83% 25% ;
  }
}
/***stepper*/
/* .ant-steps-item-icon:after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
} */
.stepperChery .ant-steps {padding: 25px 0;}
.stepperChery .ant-steps-item-container {display: flex;flex-direction: column;align-items: center;}

.stepperChery .ant-steps-item-title {
  white-space: pre-wrap;
}


.stepperChery span.ant-steps-icon:after {
  position: absolute;
  top: 10px;
  left: 100%;
  /* display: block; */
  width: 9999px;
  height: 3px;
  background: #999999;
  content: '';
}


.stepperChery .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:after {
  background-color: #85D366;
  width: 10vw;
}

.stepperChery .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before {
  background-color: #85D366;
  width: 10vw;
}
.stepperChery.err .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before {
  background-color: #c00b20;

}

.stepperChery span.ant-steps-icon:before {
  position: absolute;
  top: 10px;
  right: 100%;
  /* display: block; */
  width: 9999px;
  height: 3px;
  background: #999999;
  content: '';
}
.stepperChery .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item:nth-child(1) .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before {
  background-color:#f0f8ff00;
}
.stepperChery .ant-steps-item.ant-steps-item-finish .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before{
  background-color: #85D366;
  width: 10vw;
}
.stepperChery .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  margin-right: auto;
  white-space: nowrap;
}
.stepperChery .ant-steps-item-title::after {
    position: absolute;
    top: 10px;
    left: 100%;
    display: block;
    width: 0;
    height: 3px;
    background: #999999;
}

.stepperChery .ant-steps-item-process .ant-steps-item-icon {
  background: #85D366;
  border-color: #85D366;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px #85D366
}
.stepperChery.err .ant-steps-item-process .ant-steps-item-icon {
  background: #c00b20;
  border-color: #c00b20;
  border: 1px solid #fff;
  box-shadow: 0 0 0 1px #c00b20;
}
.ant-steps-item-icon {margin: 1px 8px 0 0;}

.stepperChery .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}

.stepperChery .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #85d366;
  border-color: #85d366;
}
.stepperChery .ant-steps-item-title {
  font-style: normal;
  font-weight: 800!important;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #5B6F81;
}


.stepperChery .stepper {
  background: #FFFFFF;
  box-shadow: 0px 2px 9px rgba(227, 237, 248, 0.628651);
  border-radius: 14px;
  min-height: 540px;
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
}

.stepperChery .steps-content {
  width: 370px;
  margin: auto;
}
.stepperChery h2 {
  font-family: 'HelveticaNeue';
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.433384px;
  color: #051F61;
  font-weight: 600;
  text-align: left;
}

.stepperChery .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
.stepperChery .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #999999;
  border-color: #999999;
}
.stepperChery .ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:after {
  background-color: #85D366;
  width: 10vw;
}
.stepperChery.err .ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:after {
  background-color: #c00b20;

}
.stepperChery .ant-steps-item.ant-steps-item-wait:last-child span.ant-steps-icon:after{
  display: none;
}
.etat{
      font-size: 25px;
    font-weight: bold;
    text-transform: capitalize;
    color: #051F61;
}
@media screen and (max-width:500px){
  .headImg img {
    width: 300px!important;
}
.ant-steps-item-title {
    text-align: left !important;
}
.stepperChery .steps-content{
  width: auto !important;
}
}
@media screen and  (max-width: 767px){
  
  .sectionTwo .two{flex-direction: column;}
  .sectionThree .three{flex-direction: column-reverse;}
  
  .headImg img {width: 375px;}
  .Hcontent h1, .contentLogin h1{font-size: 25px;}
  .firstRow{flex-direction: column;}
  .twoColumn {text-align: left;}
  .sectionNews .News {width: 80%;}
  .customInput {width: 80%;}
  .sectionNews input[type="email"]{width:70%}
  .sectionNews input[type="submit"]{width:30%}
  .bgLogin {padding: 50px 0;}
  .formLogin {width: 92%;}
  #login input[type="number"], #login input[type="text"] {width: 93%;}
}
@media screen and (max-width:991px){
.stepperChery.err .ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:after,.stepperChery.err .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before {
  display: none;

}
  .stepperChery .ant-steps-item.ant-steps-item-finish .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before,
  .stepperChery .ant-steps-item.ant-steps-item-process.ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:before {background-color: #85d36600;}
  .stepperChery .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {background-color: #999999;}
  .stepperChery span.ant-steps-icon:before {background: #85d36600;}
  .stepperChery .ant-steps-item-icon {margin: 1px 8px 0 2px;}
  .stepperChery .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail:after {background-color: #999999;}
  .stepperChery .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:after {background-color: #85d36600;}
  .stepperChery span.ant-steps-icon:after {
    position: absolute;
    top: 10px;
    left: 100%;
    /* display: block; */
    width: 9999px;
    height: 3px;
    background: #99999900;
    content: '';
} 
.stepperChery .ant-steps-item-active .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon:after {
  background-color: rgba(133, 211, 102, 0);
  width: 10vw;
}


.connect img, .popImg img {width: 300px!important;}
.stepperChery .ant-steps-horizontal.ant-steps-label-horizontal {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
.stepperChery .ant-steps-item-title {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.stepperChery .ant-steps-item-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.stepperChery .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 3px;
  height: 100%;
}
.stepperChery .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.stepperChery .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #85D366;
}
.stepperChery.err .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after{
  background-color: #c00b20;
}
.stepperChery .ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #85D366;
  border-radius: 1px;
  -webkit-transition: background 0.3s;
  transition: background 0.3s;
  content: '';
}
.stepperChery .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 10px;
    left: 16px;
  width: 1px;
  height: 100%;
  padding: 23px 0 6px;
}
 
}
@media screen and (max-width:1199px){
  .container{
    width: 80%;
    max-width: 1080px;
    margin: auto;
  }
  .stepperChery {margin: 24px 0;}
  .connect img, .popImg img {width: 380px;}
}

@media screen and (min-width:1200px){
  .container{
    width: 80%;
    max-width: 1080px;
    margin: auto;
  }
  .stepperChery {
    margin: 24px 0;
}
}
@media screen and (min-width:1500px){
  .container{
    width: 80%;
    max-width: 1170px;
    margin: auto;
  }
}
@media screen and (min-width:500px){
  .mobile{
    display: none !important;
  }

}
@media screen and (max-width:500px){
  .desktop{
    display: none !important;
  }
    .mobile img {
    width: 50px !important;
    margin-top: 45px !important;
  }
}